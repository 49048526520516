import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Img from '../assets/graduates.jpg'
import { Box, CircularProgress, useTheme } from "@mui/material";

export const Graduates = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <div style={{width: "80%", margin: "auto"}}>
      <br />
      <Box
        component="img"
        src={Img} // Replace with your image
        alt="Landscape"
        sx={{
          width: "100%", // Default full width
          maxWidth: "800px", // Limit max size on large screens
          height: "auto", // Maintain aspect ratio
          display: "block",
          margin: "auto",
          borderRadius: "8px", // Rounded corners

          // 🔹 Mobile: Make the image smaller
          [theme.breakpoints.down("sm")]: {
            maxWidth: "90%", // Shrinks on small screens
            borderRadius: "4px", // Less rounding for a tighter look
          },
        }}
      />

      <div id="spinner">
        <CircularProgress />
      </div>
      <div className="iframe-container">
        <iframe
          src={process.env.REACT_APP_GRADUATES_LINK}
          allowFullScreen={true}
          onLoad={() => {
            document.getElementById('spinner')!.style.display = 'none';
          }}
        >
        </iframe>
      </div>
    </div>
  )
}