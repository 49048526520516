import "../App.css";
import { Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { parseExcelData } from "../util/parseExcelData";
import { ScoreboardFullDetailsTable } from "./ScoreboardFullDetailsTable";
import { Cadet } from "../model/ScoreboardInterface";
import theme from "../theme";

export const Scoreboard = () => {
  const [data, setData] = useState<Cadet[]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!window.sessionStorage.getItem("isLoggedIn")) {
        navigate("/");
      }
      const filePath = "/scoreboard.xlsx";
      const parsedData = await parseExcelData(filePath);
      setData(parsedData);
      console.log()
    };
    fetchData();
  }, [])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: isMobile ? "95%" : "80%", margin: "auto", paddingTop: "10px" }}>
      <Typography
        variant="h3"
        sx={{ fontFamily: "Georgia", marginTop: "20px", marginBottom: "20px", fontSize: "clamp(1.7rem, 4vw, 4rem)" }}
        gutterBottom
      >
        Current Leaderboard
      </Typography>

      <ScoreboardFullDetailsTable data={data} />
    </div>
  );
};
