import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import { Image } from "react-bootstrap";
import Img from "../assets/contact-us.jpg";
import QuestionImage from "../assets/question-mark.png";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ContactUs = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
    else {
      window.scrollTo(0, 0);
    }
  }, [])

  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <br />
      <Box
        component="img"
        src={Img} // Replace with your image
        alt="Landscape"
        sx={{
          width: "100%", // Default full width
          maxWidth: "800px", // Limit max size on large screens
          height: "auto", // Maintain aspect ratio
          display: "block",
          margin: "auto",
          borderRadius: "8px", // Rounded corners

          // 🔹 Mobile: Make the image smaller
          [theme.breakpoints.down("sm")]: {
            maxWidth: "90%", // Shrinks on small screens
            borderRadius: "4px", // Less rounding for a tighter look
          },
        }}
      />
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card sx={{ marginTop: "50px" }} elevation={0}>
          <CardContent>
            <Image src={QuestionImage} width="25%" />
            <Typography
              sx={{
                fontFamily: "Helvetica",
                marginTop: "50px",
                fontSize: "clamp(1rem, 1.8vw, 1.7rem)"
              }}
              variant="h4"
            >
              If you have any questions or concerns, please reach out to your
              SMD.
            </Typography>
          </CardContent>
        </Card>
      </div>
      <br />
    </div>
  );
};
