import ExcelJS from 'exceljs';
import { Cadet } from '../model/ScoreboardInterface';

export const parseExcelData = async (filePath: string): Promise<Cadet[]> => {
  try {
    // Fetch the file from public directory
    const response = await fetch(filePath);
    console.log('Response:', response);
    const arrayBuffer = await response.arrayBuffer();
    
    // Initialize workbook
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);
    
    // Get first worksheet
    const worksheet = workbook.worksheets[0];
    
    // Prepare headers
    const headerRow = worksheet.getRow(1);
    const headers = headerRow.values as string[];
    console.log('Headers:', headers);
    
    // Process data rows
    const cadetData: Cadet[] = [];
    
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) return; // Skip header row
      
      const rowData: Record<string, any> = {};
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        rowData[headers[colNumber]] = cell.value;
      });
      
      // Transform to Cadet structure
      const cadet: Cadet = {
        rank: rowData['Rank'].result,
        primaryCadet: rowData['Primary Cadet'],
        spousePartner: rowData['Spouse/Partner'] || '',
        smd: rowData['SMD'],
        qualification: rowData['Qualification'],
        c1: {
          recruit: rowData['C1 Recruit'],
          sales: rowData['C1 Sales'],
          book: rowData['C1 Book'],
          bonusPromo: rowData['C1 Bonus Promo'],
          bonusBBC: rowData['C1 Bonus BBC'],
          bonusIncomeEarner: rowData['C1 Bonus Income Earner'],
          bonusBF: rowData['C1 Bonus BF'],
          bonusGX: rowData['C1 Bonus GX'],
          attend: rowData['C1 Attend'],
          penalty: rowData['C1 Penalty'],
          penaltyNotes: rowData['C1 Penalty Notes'] || '',
          callout: rowData['C1 Callout'] || '',
          calloutNotes: rowData['C1 Callout Notes'] || '',
          total: rowData['C1 Total'].result || 0
        },
        c2: {
          recruit: rowData['C2 Recruit'],
          sales: rowData['C2 Sales'],
          book: rowData['C2 Book'],
          bonusPromo: rowData['C2 Bonus Promo'],
          bonusBBC: rowData['C2 Bonus BBC'],
          bonusIncomeEarner: rowData['C2 Bonus Income Earner'],
          bonusBF: rowData['C2 Bonus BF'],
          bonusGX: rowData['C2 Bonus GX'],
          attend: rowData['C2 Attend'],
          penalty: rowData['C2 Penalty'],
          penaltyNotes: rowData['C2 Penalty Notes'] || '',
          callout: rowData['C2 Callout'] || '',
          calloutNotes: rowData['C2 Callout Notes'] || '',
          total: rowData['C2 Total'].result || 0
        },
        c3: {
          recruit: rowData['C3 Recruit'],
          sales: rowData['C3 Sales'],
          book: rowData['C3 Book'],
          bonusPromo: rowData['C3 Bonus Promo'],
          bonusBBC: rowData['C3 Bonus BBC'],
          bonusIncomeEarner: rowData['C3 Bonus Income Earner'],
          bonusBF: rowData['C3 Bonus BF'],
          bonusGX: rowData['C3 Bonus GX'],
          attend: rowData['C3 Attend'],
          penalty: rowData['C3 Penalty'],
          penaltyNotes: rowData['C3 Penalty Notes'] || '',
          callout: rowData['C3 Callout'] || '',
          calloutNotes: rowData['C3 Callout Notes'] || '',
          total: rowData['C3 Total'].result || 0
        },
        c4: {
          recruit: rowData['C4 Recruit'],
          sales: rowData['C4 Sales'],
          book: rowData['C4 Book'],
          bonusPromo: rowData['C4 Bonus Promo'],
          bonusBBC: rowData['C4 Bonus BBC'],
          bonusIncomeEarner: rowData['C4 Bonus Income Earner'],
          bonusBF: rowData['C4 Bonus BF'],
          bonusGX: rowData['C4 Bonus GX'],
          attend: rowData['C4 Attend'],
          penalty: rowData['C4 Penalty'],
          penaltyNotes: rowData['C4 Penalty Notes'] || '',
          callout: rowData['C4 Callout'] || '',
          calloutNotes: rowData['C4 Callout Notes'] || '',
          total: rowData['C4 Total'].result || 0
        },
        c5: {
          recruit: rowData['C5 Recruit'],
          sales: rowData['C5 Sales'],
          book: rowData['C5 Book'],
          bonusPromo: rowData['C5 Bonus Promo'],
          bonusBBC: rowData['C5 Bonus BBC'],
          bonusIncomeEarner: rowData['C5 Bonus Income Earner'],
          bonusBF: rowData['C5 Bonus BF'],
          bonusGX: rowData['C5 Bonus GX'],
          attend: rowData['C5 Attend'],
          penalty: rowData['C5 Penalty'],
          penaltyNotes: rowData['C5 Penalty Notes'] || '',
          callout: rowData['C5 Callout'] || '',
          calloutNotes: rowData['C5 Callout Notes'] || '',
          total: rowData['C5 Total'].result || 0
        },
        total: rowData['Total'].result || 0
      };
      
      cadetData.push(cadet);
    });
    
    return cadetData;
  } catch (error) {
    console.error('Error parsing Excel file:', error);
    throw new Error('Failed to parse Excel file');
  }
};