import { createTheme } from "@mui/material/styles";

// Extend the MUI palette
declare module "@mui/material/styles" {
  interface Palette {
    customColors: {
      skyBlue: { bg: string; text: string; hover: string };
      aquaMint: { bg: string; text: string; hover: string };
      warmPeach: { bg: string; text: string; hover: string };
      softLime: { bg: string; text: string; hover: string };
      modernSilver: { bg: string; text: string; hover: string };
    };
  }
  interface PaletteOptions {
    customColors?: {
      skyBlue?: { bg: string; text: string; hover: string };
      aquaMint?: { bg: string; text: string; hover: string };
      warmPeach?: { bg: string; text: string; hover: string };
      softLime?: { bg: string; text: string; hover: string };
      modernSilver?: { bg: string; text: string; hover: string };
    };
  }
}

const theme = createTheme({
  palette: {
    customColors: {
      skyBlue: { bg: "#74C0FC", text: "#113C57", hover: "#A0D8FF" },
      aquaMint: { bg: "#8CE4C7", text: "#1D3B30", hover: "#B2F0DD" },
      warmPeach: { bg: "#FFB997", text: "#522B18", hover: "#FFCBB0" },
      softLime: { bg: "#D4E157", text: "#374000", hover: "#E8ED85" },
      modernSilver: { bg: "#E5E5E5", text: "#404040", hover: "#F0F0F0" },
    },
  },
});

export default theme;
