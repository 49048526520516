import Img from "../assets/enrollment.jpg";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";

export const Enrollment = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    
    <div style={{ width: "100%", margin: "auto" }}>
      <br />
      <Box
        component="img"
        src={Img} // Replace with your image
        alt="Landscape"
        sx={{
          width: "100%", // Default full width
          maxWidth: "800px", // Limit max size on large screens
          height: "auto", // Maintain aspect ratio
          display: "block",
          margin: "auto",
          borderRadius: "8px", // Rounded corners

          // 🔹 Mobile: Make the image smaller
          [theme.breakpoints.down("sm")]: {
            maxWidth: "90%", // Shrinks on small screens
            borderRadius: "4px", // Less rounding for a tighter look
          },
        }}
      />
      <br />
      <br />
      <Box
        sx={{
          width: "100%",
          maxWidth: "900px", // Default max width
          mx: "auto",
          height: "66vh", // Default height
          "@media (max-width: 900px)": {
            height: "75vh", // Medium screens
          },
          "@media (max-width: 600px)": {
            height: "65vh", // Mobile screens
          },
        }}
      >
        <iframe
          title="JotForm"
          src={process.env.REACT_APP_ENROLLMENT_JOTFORM} // Replace with your form URL
          width="100%"
          height="100%"
          style={{ border: "none" }}
          allowFullScreen
        ></iframe>
      </Box>
    </div>
  );
};
