import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Home } from "./components/Home";
import { ContactUs } from "./components/ContactUs";
import { Enrollment } from "./components/Enrollment";
import { Graduates } from "./components/Graduates";
import { RulesProtocol } from "./components/RulesProtocol";
import { NavigationBar } from "./components/NavigationBar";
import { Homework } from "./components/Homework";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Scoreboard } from "./components/Scoreboard";
import { Login } from "./components/Login";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleLoginChange = (isLoggedIn: boolean) => {
    setIsLoggedIn(isLoggedIn);
    window.sessionStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
  }
  useEffect(() => {
    setIsLoggedIn(JSON.parse(window.sessionStorage.getItem("isLoggedIn") || "false"));
  }, [])
  return (
    <>
      <div className="App">
        <HashRouter>
          {isLoggedIn && <NavigationBar />}
          <Routes>
            <Route path="/" element={isLoggedIn ? <Home /> : <Login onLoginChange={handleLoginChange} isLoggedIn={isLoggedIn} />} />
            <Route path="/enrollment" element={<Enrollment />} />
            <Route path="/rules-protocol" element={<RulesProtocol />} />
            <Route path="/scoreboard" element={<Scoreboard />} />
            <Route path="/graduates" element={<Graduates />} />
            <Route path="/homework" element={<Homework />} />
            <Route path="/contact-us" element={<ContactUs />} />
          </Routes>
        </HashRouter>
      </div>
      <footer className="footer">
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "clamp(0.7rem, 1.8vw, 1rem)", color: "white" }}
          color="textSecondary"
          align="center"
        >
          © 2025 Canada Elite Circle. All rights reserved - For WFG Coded Associates
          Internal Use Only
        </Typography>
      </footer>
    </>
  );
}

export default App;
