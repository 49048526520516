import React, { useState, useMemo } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  styled,
  useTheme,
  Drawer,
  Modal,
  Typography,
  IconButton,
  Divider,
  Box,
  useMediaQuery,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Close, Visibility } from "@mui/icons-material";
import { Cadet } from "../model/ScoreboardInterface";

const StyledHeader = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.grey[100],
  position: "sticky",
  top: 0,
  zIndex: 1,
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
  maxHeight: "80vh",
  overflow: "auto",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.grey[500],
    borderRadius: "3px",
  },
}));

export const ScoreboardFullDetailsTable: React.FC<{ data: Cadet[] }> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Cadet>("rank");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCadet, setSelectedCadet] = useState<Cadet | null>(null);
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [permanentDrawer, setPermanentDrawer] = useState(false);

  // Process, sort and filter data
  const processedData = useMemo(() => {
    let result = [...data];

    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter((cadet) =>
        Object.entries(cadet).some(([key, value]) => {
          if (key === "c1" || key === "c2" || key === "c3" || key === "c4" || key === "c5") {
            return Object.values(value).some((v) =>
              String(v)
                .toLowerCase()
                .includes(term)
            );
          }
          return String(value)
            .toLowerCase()
            .includes(term);
        })
      );
    }

    result.sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (aValue < bValue) return order === "asc" ? -1 : 1;
      if (aValue > bValue) return order === "asc" ? 1 : -1;
      return 0;
    });

    return result;
  }, [data, searchTerm, order, orderBy]);

  const handleSort = (property: keyof Cadet) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleRowClick = (cadet: Cadet) => {
    setSelectedCadet(cadet);
  };

  const handleCloseDrawer = () => {
    setSelectedCadet(null);
  };

  const handleDetailKeyToString = (key: string) => {
    switch (key) {
      case "recruit":
        return "Recruit";
      case "sales":
        return "Sales";
      case "book":
        return "Book";
      case "bonusPromo":
        return "Bonus Promo";
      case "bonusBBC":
        return "Bonus BBC";
      case "bonusIncomeEarner":
        return "Bonus Income Earner";
      case "bonusBF":
        return "Bonus BF";
      case "bonusGX":
        return "Bonus GX";
      case "attend":
        return "Attend";
      case "penalty":
        return "Penalty";
      case "penaltyNotes":
        return "Penalty Notes";
      case "callout":
        return "Callout";
      case "calloutNotes":
        return "Callout Notes";
      case "total":
        return "Total";
      default:
        return key;
    }
  };

  const renderDetailsContent = () =>
    selectedCadet && (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6">{selectedCadet.primaryCadet}</Typography>
          <IconButton onClick={handleCloseDrawer}>
            <Close />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 2,
            mb: 2,
          }}
        >
          <DetailItem label="Rank" value={selectedCadet.rank} />
          <DetailItem label="SMD" value={selectedCadet.smd} />
          {selectedCadet.spousePartner !== "" && <DetailItem label="Spouse/Partner" value={selectedCadet.spousePartner} />}
          <DetailItem label="Total" value={selectedCadet.total.toFixed(2)} bold />
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "bold" }}>
          Class Breakdown
        </Typography>

        {["c1", "c2", "c3", "c4", "c5"].map((cycle) => (
          <Box key={cycle} sx={{ mb: 3 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1 }}>
              {cycle.replace("c", "Class ")} Details
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
                gap: 1.5,
              }}
            >
              {selectedCadet &&
                Object.entries(selectedCadet[cycle as keyof Cadet])
                  .filter(([key]) => key !== "total")
                  .map(([key, value]) => (
                    <DetailItem
                      key={key}
                      label={handleDetailKeyToString(key)}
                      value={value !== null && typeof value === "number" ? value.toFixed(2) : value || "-"}
                      small
                    />
                  ))}
            </Box>
          </Box>
        ))}
      </>
    );

  return (
    <>
      <Paper
        sx={{
          width: permanentDrawer ? "60%" : "100%",
          overflow: "hidden",
          transition: "width 0.2s ease",
          "& .MuiTableCell-root": {
            padding: isMobile ? "8px 4px" : "8px 16px",
            fontSize: isMobile ? "0.75rem" : "0.875rem",
          },
        }}
      >
        <TableContainer
          sx={{
            maxHeight: "calc(100vh - 200px)",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              height: "8px",
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.grey[400],
              borderRadius: "4px",
            },
          }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <StyledHeader>Rank</StyledHeader>
                <StyledHeader>Primary</StyledHeader>
                <StyledHeader>Spouse/Partner</StyledHeader>
                <StyledHeader>SMD</StyledHeader>
                <StyledHeader sx={{ minWidth: isMobile ? "80px" : "auto" }}>Qual</StyledHeader>
                <StyledHeader>C1</StyledHeader>
                <StyledHeader>C2</StyledHeader>
                <StyledHeader>C3</StyledHeader>
                <StyledHeader>C4</StyledHeader>
                <StyledHeader>C5</StyledHeader>
                <StyledHeader>Total</StyledHeader>
                <StyledHeader sx={{ width: "40px" }}></StyledHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {processedData.map((cadet, index) => (
                <TableRow
                  key={`${cadet.rank}-${cadet.smd}`}
                  hover
                  onClick={() => handleRowClick(cadet)}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: theme.palette.action.hover },
                    // Highlight top 10 rows
                    backgroundColor: index < 10 ? '#7cf28f' : undefined,
                    "& .MuiTableCell-root": {
                      // Make text bold for top 10
                      fontWeight: index < 10 ? 'bold' : undefined,
                    }
                  }}
                >
                  <TableCell>
                    {index < 3 ? (
                      <Box
                        component="span"
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {cadet.rank === 1 && "🥇"}
                        {cadet.rank === 2 && "🥈"}
                        {cadet.rank === 3 && "🥉"}
                        {cadet.rank}
                      </Box>
                    ) : (
                      cadet.rank
                    )}
                  </TableCell>
                  <TableCell>{cadet.primaryCadet}</TableCell>
                  <TableCell>{cadet.spousePartner}</TableCell>
                  <TableCell>{cadet.smd}</TableCell>
                  <TableCell>{cadet.qualification.toFixed(2)}</TableCell>
                  <TableCell>{cadet.c1.total.toFixed(2)}</TableCell>
                  <TableCell>{cadet.c2.total.toFixed(2)}</TableCell>
                  <TableCell>{cadet.c3.total.toFixed(2)}</TableCell>
                  <TableCell>{cadet.c4.total.toFixed(2)}</TableCell>
                  <TableCell>{cadet.c5.total.toFixed(2)}</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>{cadet.total.toFixed(2)}</TableCell>
                  <TableCell>
                    <Visibility
                      sx={{
                        opacity: hoveredRow === index ? 1 : 0,
                        transition: "opacity 0.2s",
                        color: theme.palette.primary.main,
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Drawer Component */}
      {!isMobile && Boolean(selectedCadet) && (
        <Drawer
          variant={!isMobile && permanentDrawer ? "permanent" : "temporary"}
          anchor={isMobile ? "bottom" : "right"}
          open={isMobile ? Boolean(selectedCadet) : true}
          onClose={handleCloseDrawer}
          sx={{
            "& .MuiDrawer-paper": {
              width: isMobile ? "100%" : "30vw", // Fixed width for permanent drawer
              height: isMobile ? "70vh" : "100%",
              boxSizing: "border-box",
              padding: theme.spacing(3),
            },
          }}
        >
          {/* Drawer Header with Toggle and Close Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              borderBottom: `1px solid ${theme.palette.divider}`,
              position: "sticky",
              top: 0,
              bgcolor: "background.paper",
              zIndex: 1,
            }}
          >
            <Typography variant="h6">{(selectedCadet && selectedCadet.primaryCadet) || "Details"}</Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {!isMobile && (
                <FormControlLabel
                  control={
                    <Switch size="small" checked={permanentDrawer} onChange={() => setPermanentDrawer(!permanentDrawer)} color="primary" />
                  }
                  label="Pin"
                  labelPlacement="start"
                  sx={{ mr: 0 }}
                />
              )}
              {(!permanentDrawer || isMobile) && (
                <IconButton onClick={handleCloseDrawer} size="small">
                  <Close fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Box>

          {/* Drawer Content */}
          <Box sx={{ p: 2, overflow: "auto", height: "calc(100% - 64px)" }}>
            {selectedCadet && <>{selectedCadet && renderDetailsContent()}</>}
          </Box>
        </Drawer>
      )}

      {/* Mobile Modal - Simple Scrollable Content */}
      {isMobile && (
        <Modal
          open={Boolean(selectedCadet)}
          onClose={handleCloseDrawer}
          sx={{
            // display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "95vw",
              maxHeight: "90vh",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 2,
              overflow: "auto",
            }}
          >
            {selectedCadet && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    pb: 1,
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {selectedCadet.primaryCadet}
                  </Typography>
                  <IconButton onClick={handleCloseDrawer} size="small">
                    <Close />
                  </IconButton>
                </Box>

                {/* Simple Key Metrics */}
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    Rank: {selectedCadet.rank}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    SMD: {selectedCadet.smd}
                  </Typography>
                  {selectedCadet.spousePartner !== "" && (
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      Spouse/Partner: {selectedCadet.spousePartner}
                    </Typography>
                  )}
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Total: {selectedCadet.total.toFixed(2)}
                  </Typography>
                </Box>

                {/* Simple Cycle Data */}
                <Box sx={{ mt: 2 }}>
                  {["c1", "c2", "c3", "c4", "c5"].map((cycle) => (
                    <Box key={cycle} sx={{ mb: 3 }}>
                      <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1 }}>
                        {cycle.replace("c", "Class ")} Details
                      </Typography>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: 1,
                        }}
                      >
                        {Object.entries(selectedCadet[cycle as keyof Cadet])
                          .filter(([key]) => key !== "total")
                          .map(([key, value]) => (
                            <Box key={key} sx={{ mb: 1 }}>
                              <Typography variant="caption" color="text.secondary">
                                {handleDetailKeyToString(key)}:
                              </Typography>
                              <Typography variant="body2">{typeof value === "number" ? value.toFixed(2) : value || "-"}</Typography>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};

// Helper component for consistent detail items
const DetailItem = ({
  label,
  value,
  bold = false,
  small = false,
}: {
  label: string;
  value?: string | number;
  bold?: boolean;
  small?: boolean;
}) => (
  <Box>
    <Typography variant={small ? "caption" : "body2"} color="text.secondary" sx={{ lineHeight: 1.2 }}>
      {label}
    </Typography>
    <Typography variant={small ? "body2" : "body1"} sx={{ fontWeight: bold ? "bold" : "normal", lineHeight: 1.2 }}>
      {value || "-"}
    </Typography>
  </Box>
);
