import { Button, Container, Image, Nav, Navbar, NavItem, Offcanvas } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from '../assets/Logo.png';
import React, { useState } from "react";
import '../App.css';

export const NavigationBar = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    setShow(false);
    window.sessionStorage.setItem("isLoggedIn", JSON.stringify(false));
    window.location.href = "/";
  }
  
  return (
    <>
      <Navbar expand="lg" bg="light" variant="light" fixed="top" sticky="top">
        <Container>
          {/* Brand */}
          <Navbar.Brand href="/" as={Link} to="/"><Image src={Logo} height="60vw" /></Navbar.Brand>

          {/* Mobile Menu Button */}
          <Button
            variant="outline-dark"
            className="d-lg-none"
            onClick={() => setShow(true)}
          >
            ☰
          </Button>

          {/* Desktop Navigation */}
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav fill variant="pills" activeKey={location.pathname} className="mr-auto">
              <Nav.Item>
                <Nav.Link eventKey="/" as={Link} to="/">Home</Nav.Link>
              </Nav.Item>
              <NavItem>
                <Nav.Link eventKey="/enrollment" as={Link} to="/enrollment">Enrollment</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link eventKey="/rules-protocol" as={Link} to="/rules-protocol">Rules/Protocol</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link eventKey="/graduates" as={Link} to="/graduates">Graduates</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link eventKey="/homework" as={Link} to="/homework">Homework</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link eventKey="/scoreboard" as={Link} to="/scoreboard">Scoreboard</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link eventKey="/contact-us" as={Link} to="/contact-us">Contact Us</Nav.Link>
              </NavItem>
              <NavItem>
                <Nav.Link onClick={handleLogout} as={Link} to="/">Logout</Nav.Link>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Mobile Offcanvas (Sidebar) */}
      <Offcanvas show={show} onHide={() => setShow(false)} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><Image src={Logo} height="60vw" /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column mobileNav" activeKey={location.pathname}>
            <Nav.Link eventKey="/" as={Link} to="/" onClick={() => setShow(false)}>Home</Nav.Link>
            <Nav.Link eventKey="/enrollment" as={Link} to="/enrollment" onClick={() => setShow(false)}>Enrollment</Nav.Link>
            <Nav.Link eventKey="/rules-protocol" as={Link} to="/rules-protocol" onClick={() => setShow(false)}>Rules/Protocol</Nav.Link>
            <Nav.Link eventKey="/graduates" as={Link} to="/graduates" onClick={() => setShow(false)}>Graduates</Nav.Link>
            <Nav.Link eventKey="/homework" as={Link} to="/homework" onClick={() => setShow(false)}>Homework</Nav.Link>
            <Nav.Link eventKey="/scoreboard" as={Link} to="/scoreboard" onClick={() => setShow(false)}>Scoreboard</Nav.Link>
            <Nav.Link eventKey="/contact-us" as={Link} to="/contact-us" onClick={() => setShow(false)}>Contact Us</Nav.Link>
            <Nav.Link onClick={handleLogout} as={Link} to="/">Logout</Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
