import "../App.css";
import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const RulesProtocol = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "Georgia",
          marginTop: "20px",
          marginBottom: "20px",
          fontSize: "clamp(1.7rem, 4vw, 4rem)",
        }}
        gutterBottom
      >
        Rules / Protocol
      </Typography>

      <div id="spinner">
        <CircularProgress />
      </div>
      <div className="iframe-container">
        <iframe
          src={process.env.REACT_APP_RULES_PROTOCOL_LINK}
          allowFullScreen={true}
          onLoad={() => {
            document.getElementById('spinner')!.style.display = 'none';
          }}
        >
        </iframe>
      </div>
    </div>
  );
};
