import "../App.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import theme from "../theme";

export const Homework = () => {
  const [url, setUrl] = useState("");

  const handleClick = (path: string | undefined) => {
    if(path === url) {
      setUrl("");
      return;
    }
    setUrl(path as string);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if(!window.sessionStorage.getItem("isLoggedIn")) {
      navigate("/");
    }
  }, [])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{width: "80%", margin: "auto"}}>
      <Typography
        variant="h3"
        sx={{
          fontFamily: "Georgia",
          marginTop: "20px",
          marginBottom: "20px",
          fontSize: "clamp(1.7rem, 4vw, 4rem)",
        }}
        gutterBottom
      >
        Homework
      </Typography>

      <Grid container spacing={2} justifyContent="center" pb={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            onClick={() => handleClick(process.env.REACT_APP_RECRUIT_HW_JOTFORM)}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.skyBlue.bg,
              color: theme.palette.customColors.skyBlue.text,
              "&:hover": { backgroundColor: theme.palette.customColors.skyBlue.hover }
            }}
          >
            Recruit
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Button
            onClick={() => handleClick(process.env.REACT_APP_SALES_HW_JOTFORM)}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.aquaMint.bg,
              color: theme.palette.customColors.aquaMint.text,
              "&:hover": { backgroundColor: theme.palette.customColors.aquaMint.hover }
            }}
          >
            Sales
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Button
            onClick={() => handleClick(process.env.REACT_APP_EVENTS_HW_JOTFORM)}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.warmPeach.bg,
              color: theme.palette.customColors.warmPeach.text,
              "&:hover": { backgroundColor: theme.palette.customColors.warmPeach.hover }
            }}
          >
            Events
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Button
            onClick={() => handleClick(process.env.REACT_APP_BONUS_HW_JOTFORM)}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.softLime.bg,
              color: theme.palette.customColors.softLime.text,
              "&:hover": { backgroundColor: theme.palette.customColors.softLime.hover, color: theme.palette.customColors.softLime.text }
            }}
          >
            Bonus
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            onClick={() => handleClick(process.env.REACT_APP_BOOK_SUMMARY_HW_JOTFORM)}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.modernSilver.bg,
              color: theme.palette.customColors.modernSilver.text,
              "&:hover": { backgroundColor: theme.palette.customColors.modernSilver.hover }
            }}
          >
            Book Summary
          </Button>
        </Grid>
      </Grid>

      {url !== "" && (
        <Box
        sx={{
          width: "100%",
          maxWidth: "900px", // Default max width
          mx: "auto",
          height: "66vh", // Default height
          "@media (max-width: 900px)": {
            height: "75vh", // Medium screens
          },
          "@media (max-width: 600px)": {
            height: "65vh", // Mobile screens
          },
        }}
      >
        <iframe
          title="JotForm"
          src={url} // Replace with your form URL
          width="100%"
          height="100%"
          style={{ border: "none" }}
          allowFullScreen
        ></iframe>
      </Box>
      )}

      <div>
        <div id="spinner">
          <CircularProgress />
        </div>
        <div className="iframe-container">
          <iframe
            src={process.env.REACT_APP_HW_SLIDES}
            allowFullScreen={true}
            onLoad={() => {
              document.getElementById('spinner')!.style.display = 'none';
            }}
          >
          </iframe>
        </div>
      </div>  
    </div>
  );
};
