import "../App.css";
import { Button, CardContent, CircularProgress, Grid, Typography } from "@mui/material";
import { Col, Container, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import theme from "../theme";

export const Home = () => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <Row xs={12}>
          <Col xs={12} md={12}>
            <Card
              className="mx-auto"
              style={{ marginLeft: "30px", border: "none", boxShadow: "none" }}
            >
              <CardContent sx={{ textAlign: "left" }}>
                <Typography
                  sx={{ 
                    fontSize: "clamp(1.5rem, 2vw, 2rem)",
                    fontWeight: "bold"
                   }}
                  color="#F7B042"
                  variant="h6"
                  gutterBottom
                >
                  WELCOME TO
                </Typography>
                <Typography
                  sx={{ 
                    fontSize: "clamp(1.7rem, 4vw, 4rem)",
                    fontFamily: "Georgia", 
                  }}
                  variant="h2"
                >
                  Canada Elite Circle
                </Typography>
                <Typography 
                sx={{ 
                  fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
                  fontFamily: "Helvetica"
                 }} 
                variant="h5">
                  Here you will check the schedule, submit your homework, and
                  view your rankings.
                </Typography>
              </CardContent>
            </Card>
          </Col>
        </Row>
      </Container>

      <Grid container spacing={2} justifyContent="center" pb={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            onClick={() => handleClick("/enrollment")}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.skyBlue.bg,
              color: theme.palette.customColors.skyBlue.text,
              "&:hover": { backgroundColor: theme.palette.customColors.skyBlue.hover }
            }}
          >
            ENROLL NOW
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Button
            onClick={() => handleClick("/rules-protocol")}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.aquaMint.bg,
              color: theme.palette.customColors.aquaMint.text,
              "&:hover": { backgroundColor: theme.palette.customColors.aquaMint.hover }
            }}
          >
            RULES/PROTOCOL
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Button
            onClick={() => handleClick("/scoreboard")}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.warmPeach.bg,
              color: theme.palette.customColors.warmPeach.text,
              "&:hover": { backgroundColor: theme.palette.customColors.warmPeach.hover }
            }}
          >
            SCOREBOARD
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <Button
            href="https://docs.google.com/forms/d/e/1FAIpQLSeuJ8bfutWhJ0P5FVEPTQrLlmoC-9raEBn8xt2tzdzS2HCh4Q/viewform"
            target="_blank"
            rel="noreferrer noopener"
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.softLime.bg,
              color: theme.palette.customColors.softLime.text,
              "&:hover": { backgroundColor: theme.palette.customColors.softLime.hover, color: theme.palette.customColors.softLime.text }
            }}
          >
            BPM SIGN IN
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            onClick={() => handleClick("/graduates")}
            variant="contained"
            sx={{ 
              width: '100%', 
              fontSize: "clamp(1rem, 1.8vw, 1.7rem)",
              backgroundColor: theme.palette.customColors.modernSilver.bg,
              color: theme.palette.customColors.modernSilver.text,
              "&:hover": { backgroundColor: theme.palette.customColors.modernSilver.hover }
            }}
          >
            GRADUATES
          </Button>
        </Grid>
      </Grid>

    <div id="spinner">
      <CircularProgress />
    </div>
    <div className="iframe-container">
      <iframe
        src={process.env.REACT_APP_CLASS_INFO_LINK}
        allowFullScreen={true}
        onLoad={() => {
          document.getElementById('spinner')!.style.display = 'none';
        }}
      >
      </iframe>
    </div>
    </div>
  );
};
